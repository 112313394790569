import Swal from 'sweetalert2'
window.Swal = Swal;


window.initCap = function(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}


window.toggle = function(thebutton) {
    let val;
    if (thebutton.checked) {
        val = true;
    } else {
        val = false;
    }

    let len = document.target.elements.length;

    for (let i = 0; i < len; i++) {
        let button = document.target.elements[i];

        let name_array = button.name.split("[");

        if (name_array[0] == "toggle") {
            button.checked = val;
        }
    }

    document.target.toggleflag.checked = val;
}

window.toggle2 = function(checkBox, className) {
    if (checkBox.checked) {
        $("input[type='checkbox']." + className).attr('checked', 'checked');
    } else {
        $("input[type='checkbox']." + className).removeAttr('checked');
    }
    return true;
}

window.convertkgs2lbs = function(val, updfld) {
    let conval = Math.round(val * 2.2046);
    document.entryForm.actwgtlbs.value = conval;

    if (isNaN(parseInt(document.entryForm.actwgtkgs.value))) {
        document.entryForm.actwgtkgs.value = 0;
    }
    if (isNaN(parseInt(document.entryForm.dimwgtkgs.value))) {
        document.entryForm.dimwgtkgs.value = 0;
    }

    if (parseInt(document.entryForm.actwgtkgs.value) > parseInt(document.entryForm.dimwgtkgs.value)) {
        document.entryForm.chgwgtlbs.value = document.entryForm.actwgtlbs.value;
        document.entryForm.chgwgtkgs.value = document.entryForm.actwgtkgs.value;
    } else {
        document.entryForm.chgwgtlbs.value = document.entryForm.dimwgtlbs.value;
        document.entryForm.chgwgtkgs.value = document.entryForm.dimwgtkgs.value;
    }
}

window.convertlbs2kgs = function(val, updfld) {
    let conval = Math.round(val * 0.4536 * 100) / 100;
    document.entryForm.actwgtkgs.value = conval;

    if (isNaN(parseInt(document.entryForm.actwgtlbs.value))) {
        document.entryForm.actwgtlbs.value = 0;
    }
    if (isNaN(parseInt(document.entryForm.dimwgtlbs.value))) {
        document.entryForm.dimwgtlbs.value = 0;
    }

    if (parseInt(document.entryForm.actwgtlbs.value) > parseInt(document.entryForm.dimwgtlbs.value)) {
        document.entryForm.chgwgtlbs.value = document.entryForm.actwgtlbs.value;
        document.entryForm.chgwgtkgs.value = document.entryForm.actwgtkgs.value;
    } else {
        document.entryForm.chgwgtlbs.value = document.entryForm.dimwgtlbs.value;
        document.entryForm.chgwgtkgs.value = document.entryForm.dimwgtkgs.value;
    }
}

window.saveForm = function() {
    window.formchanged = 0;
    document.entryForm.submit();
}


window.onbeforeunload = window.check_changed;

window.formchanged = 0;

window.check_changed = function() {
    if (window.formchanged) {
        return "You have made modifications to the current page.\nIf you navigate away from this page, you will lose them!\nAre you sure you wish to navigate away from this page?";
    }
}

window.changed = function(num) {
    let ElementID = "dynText" + num;
    window.formchanged = 1;
    document.getElementById(ElementID).innerHTML = "Form has been changed";
}

window.unchanged = function() {
    window.formchanged = 0;
}

window.popUp = function (strURL, strType, strHeight, strWidth, winName) {
    let strOptions = "";
    if (winName == "") winName = "newWin";
    if (strType == "console") strOptions = "resizable,height=" + strHeight + ",width=" + strWidth;
    if (strType == "fixed") strOptions = "status,height=" + strHeight + ",width=" + strWidth;
    if (strType == "elastic") strOptions = "scrollbars,resizable,status,height=" + strHeight + ",width=" + strWidth;

    //	if(!window.openedWindows)
    //	{
    //		window.openedWindows = [];
    //	}
    //	window.openedWindows.push(window.open(strURL, winName , strOptions));
    let newwindow = window.open(strURL, winName, strOptions);
}

window.checkboxChange = function () {

}

//let toggle = 1;

window.SetAllCheckBoxes = function (FormName, FieldName, CheckValue) {
    let toggle;

    if (toggle)
        toggle = 0;
    else
        toggle = 1;

    if (!document.forms[FormName])
        return;
    let objCheckBoxes = document.forms[FormName].elements[FieldName];
    if (!objCheckBoxes)
        return;
    let countCheckBoxes = objCheckBoxes.length;
    if (!countCheckBoxes)
        objCheckBoxes.checked = CheckValue;
    else
        // set the check value for all check boxes
        for (let i = 0; i < countCheckBoxes; i++)
            objCheckBoxes[i].checked = CheckValue;
}

window.trim = function (str) {
    return str.replace(/^s&|\s*$/g, "");
}


window.formatMTime = function (textObj) {
    let val = textObj.value;

    let hours,min,arrVals,part1,part2;

    if (val.indexOf(':') < 0) {
        hours = trim(val.substring(0, 2));
        min = trim(val.substring(2, 4));

    } else {
        arrVals = val.split(':');
        part1 = arrVals[0];
        part2 = arrVals[1];
        hours = trim(part1.substring(0, 2));
        min = trim(part2.substring(0, 2));
    }


    if (hours > 23) {
        hours = 23;
    }

    if (min > 59) {
        min = 59;
    }

    if (isNaN(min) || min == '' || null == min) {
        min = '00';
    }

    if (isNaN(hours) || hours == '' || null == hours) {
        hours = '00';
    }


    if (val == '') {
        textObj.value = '';
    } else {
        textObj.value = hours + ':' + min;
    }


}

function showHide(entryID, entryLink, htmlObj) {

    let extTextDivID = ('extText' + (entryID));
    let extLinkDivID = ('extLink' + (entryID));

    if (document.getElementById) {
        if (document.getElementById(extTextDivID).style.display) {
            if (entryLink != 0) {
                document.getElementById(extTextDivID).style.display = "block";
                document.getElementById(extLinkDivID).style.display = "none";
                htmlObj.blur();
            } else {
                document.getElementById(extTextDivID).style.display = "none";
                document.getElementById(extLinkDivID).style.display = "block";
            }
        } else {
            location.href = entryLink;
            return true;
        }
    } else {
        location.href = entryLink;
        return true;
    }
}
window.showHide = showHide;

var isNS4 = false;


window.printToPDF = function (form, newwin) {
    if (newwin == 1) {
        window.open("about:blank", "pdfWin", "scrollbars,resizable,status,height=400,width=600");
        form.target = "pdfWin";
    }

    form.html.value = "<HTML>" + document.documentElement.innerHTML + "</HTML>";
    return true;
}

window.generatePDFFromBody = function (orientation, printbackground, margintop, marginbottom, marginleft, marginright, pageheight, pagewidth) {


    if (typeof orientation == "undefined") {
        orientation = "portrait";
    }

    if (typeof margintop == "undefined") {
        margintop = ".25";
    }

    if (typeof marginbottom == "undefined") {
        marginbottom = ".25";
    }

    if (typeof marginleft == "undefined") {
        marginleft = ".25";
    }

    if (typeof marginright == "undefined") {
        marginright = ".25";
    }

    if (typeof pageheight == "undefined") {
        pageheight = "11";
    }

    if (typeof pagewidth == "undefined") {
        pagewidth = "8.5";
    }

    if (typeof printbackground == "undefined") {
        printbackground = "no";
    }


    $.post("/pdf_processor.php?ajax=1", {
        orientation: orientation,
        margintop: margintop,
        marginbottom: marginbottom,
        marginleft: marginleft,
        marginright: marginright,
        pageheight: pageheight,
        pagewidth: pagewidth,
        printbackground: printbackground,
        html: $("body").html()
    }, function (xml) {
        if (xml.substring(0, 7) == "http://" || xml.substring(0, 8) == "https://") {
            popUp(xml, "elastic", 500, 650, "pdfWin");
        } else {
            alert("There was an error generating your PDF.  Please contact and administrator.\n" + xml);
        }

    });
}

window.roundNumber = function (rnum, rlength) { // Arguments: number to round, number of decimal places
    return Math.round(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
}

window.addqs = function (varname, value) {
    let uri = window.location.protocol + "//" + window.location.host + window.location.pathname;
    let new_qs = {};

    $.each(get_query(), function (k, v) {
        if (k != varname) {
            new_qs[k] = v;
        }
    });

    new_qs[varname] = value;

    if (new_qs.length < 1) {
        return uri;
    } else {
        let ret = uri + "?";
        $.each(new_qs, function (k, v) {
            //console.log(k);
            //console.log(v);

            ret += k + "=" + v + "&";
        });
        ret = ret.substring(0, ret.length - 1);
        return ret;
    }
}

window.removeqs = function (varname) {

    let uri = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname;
    let new_qs = {};

    $.each(get_query(), function (k, v) {
        if (k != varname) {
            new_qs[k] = v;
        }
    });

    if (new_qs.length < 1) {
        return uri;
    } else {
        let ret = uri + "?";
        $.each(new_qs, function (k, v) {
            ret += k + "=" + v + "&";
        });
        ret = ret.substring(0, ret.length - 1);
        return ret;
    }
}

window.get_query = function () {
    let url = location.href;
    let qs = url.substring(url.indexOf('?') + 1).split('&');
    for (let i = 0, result = {}; i < qs.length; i++) {
        qs[i] = qs[i].split('=');
        result[qs[i][0]] = decodeURIComponent(qs[i][1]);
    }
    return result;
}

window.formatDateInput = function (obj) {
    if (obj.value) {
        let dateArr = obj.value.split('/');
        if (dateArr[0].length == 1) {
            dateArr[0] = "0" + dateArr[0];
        }
        if (dateArr[0].length > 2) {
            dateArr[0] = dateArr[0].substring(0, 2);
        }

        if (dateArr[1].length == 1) {
            dateArr[1] = "0" + dateArr[1];
        }
        if (dateArr[1].length > 2) {
            dateArr[1] = dateArr[1].substring(0, 2);
        }

        if (dateArr[2].length > 2) {
            dateArr[2] = dateArr[2].substr(2, 4);
        }

        obj.value = dateArr.join("/");

    }

}

window.minerva_toastr = function (body, msgtype, title, passedoptions) {
    msgtype = msgtype ? msgtype : 'info';
    title = title ? title : initCap(msgtype);
    passedoptions = passedoptions ? passedoptions : {};

    if (passedoptions['autohide']) {
        passedoptions['timeOut'] = 5000;
        passedoptions['extendedTimeOut'] = 10000;
    }

    let defaultoptions = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-left",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        /*"timeOut": "5000",
        "extendedTimeOut": "1000",*/
        "timeOut": 0,
        "extendedTimeOut": 0,
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }
    toastr.options = $.extend(defaultoptions, passedoptions);
    toastr[msgtype]("<b>" + title + "</b><br>" + body);
}

window.reloadGlobalAlerts = function () {
    console.log('called reloadGlobalAlerts');
    $.ajax({
        dataType: "json",
        url: '/sys/ajax/reload-global-alerts',
        success: function (data) {
            console.log('success callback of reloadGlobalAlerts');
            updateGlobalAlertsDiv(data);
        }
    });
}

window.updateGlobalAlertsDiv = function(data) {
        console.log("updating global alerts div");
	console.log(data);
	let html = '';
	if(data.length > 0) {
		for(let i=0; i<data.length; i++)
		{
			html += "<div class='alert'>";
			if(data[i]['link'] != "")
			{
				html += "<a href='" + data[i]['link'] + "'>" + data[i]['text'] + "</a>";
			}
			else
			{
				html += data[i]['text'];
			}
			html += "</div>";
		}
	}
	$('#global_alerts').html(html);

	if(data.length < 1) {
		$('#global_alerts').addClass('global_alerts_empty');
	} else {
		$('#global_alerts').removeClass('global_alerts_empty');
	}
}

window.copyToClipboard = function (str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('Tracking URL copied to clipboard!');
}

